import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }

import Room from '@/model/rooms/Room';
	
export default /*@__PURE__*/_defineComponent({
  __name: 'RoomTopic',
  props: {
		room: {
			type: Room,
		},
	},
  setup(__props) {

	const props = __props;

return (_ctx: any,_cache: any) => {
  const _component_PrivateRoomMembersList = _resolveComponent("PrivateRoomMembersList")!

  return (__props.room?.isPrivateRoom())
    ? (_openBlock(), _createElementBlock("span", _hoisted_1, [
        _createTextVNode(_toDisplayString(_ctx.$t('rooms.private_members')) + " ", 1),
        _createVNode(_component_PrivateRoomMembersList, {
          members: __props.room?.getOtherJoinedAndInvitedMembers()
        }, null, 8, ["members"])
      ]))
    : (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(__props.room?.getTopic()), 1))
}
}

})