import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = ["title"]
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }

import { onBeforeMount, ref, watch } from 'vue';
	import { buttonsSubmitCancel, DialogButtonAction } from '@/store/dialog';
	import { FormObjectInputTemplate } from '@/composables/useFormInputEvents';
	import { AskDisclosure } from '@/lib/signedMessages';
	import { useYivi } from '@/store/yivi';
	import { useI18n } from 'vue-i18n';

	
export default /*@__PURE__*/_defineComponent({
  __name: 'AskDisclosureAttrsForm',
  props: {
    askDisclosure: {}
  },
  emits: ['submit', 'chooseUser', 'close'],
  setup(__props: any, { emit: __emit }) {

	const { t } = useI18n();
	const yivi = useYivi();
	const emit = __emit;

	const ask = ref<AskDisclosure>();

	const props = __props;

	const securedRoomTemplate = ref([
		{ key: 'yivi', label: t('admin.secured_attribute'), type: 'select', options: [], default: '' },
		{ key: 'values', label: t('admin.secured_values'), type: 'textarea', default: '' },
	] as Array<FormObjectInputTemplate>);

	watch(
		() => ask.value,
		async (result) => {
			console.debug(`new: u=${result?.user?.userId}, m=${result?.message}, a=[${result?.attributes?.map((a: any) => a.yivi)}], r=${result?.where_room}`);
		},
		{ deep: true },
	);

	onBeforeMount(async () => {
		await yivi.fetchAttributes();
		securedRoomTemplate.value[0].options = yivi.attributesOptions;
		ask.value = props.askDisclosure;
	});

	function onChooseUser() {
		const result = ask.value;
		console.debug(`AskDisclosureAttrsForm:onChooseUser: u=${result?.user?.userId}, m=${result?.message}, a=[${result?.attributes?.map((a: any) => a.yivi)}]`);
		emit('chooseUser', result!);
	}

	async function close(returnValue: DialogButtonAction) {
		const result = ask.value;
		console.debug(`AskDisclosureAttrsForm:close(${returnValue}): u=${result?.user?.userId}, m=${result?.message}, a=[${result?.attributes?.map((a: any) => a.yivi)}]`);
		if (returnValue === 1) {
			emit('submit', result!);
		}
		emit('close');
	}

return (_ctx: any,_cache: any) => {
  const _component_Label = _resolveComponent("Label")!
  const _component_Icon = _resolveComponent("Icon")!
  const _component_FormLine = _resolveComponent("FormLine")!
  const _component_TextInput = _resolveComponent("TextInput")!
  const _component_FormObjectInput = _resolveComponent("FormObjectInput")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (ask.value)
    ? (_openBlock(), _createBlock(_component_Dialog, {
        key: 0,
        title: _ctx.$t('admin.ask_disclosure_title'),
        buttons: _unref(buttonsSubmitCancel),
        onClose: _cache[4] || (_cache[4] = ($event: any) => (close($event)))
      }, {
        default: _withCtx(() => [
          _createElementVNode("form", {
            onSubmit: _cache[3] || (_cache[3] = _withModifiers(() => {}, ["prevent"]))
          }, [
            _createVNode(_component_FormLine, null, {
              default: _withCtx(() => [
                _createVNode(_component_Label, null, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('admin.ask_disclosure_user_title')), 1)
                  ]),
                  _: 1
                }),
                (ask.value.user.userId)
                  ? (_openBlock(), _createElementBlock("span", {
                      key: 0,
                      class: "cursor-pointer",
                      onClick: onChooseUser
                    }, [
                      _createElementVNode("span", null, _toDisplayString(ask.value.user.userId), 1),
                      _cache[5] || (_cache[5] = _createTextVNode(" \"")),
                      (ask.value.user.displayName)
                        ? (_openBlock(), _createElementBlock("span", {
                            key: 0,
                            title: ask.value.user.displayName
                          }, _toDisplayString(ask.value.user.displayName.substring(0, 20) + (ask.value.user.displayName.length > 20 ? '...' : '')), 9, _hoisted_1))
                        : _createCommentVNode("", true),
                      _cache[6] || (_cache[6] = _createTextVNode("\" ")),
                      _createVNode(_component_Icon, {
                        type: "edit",
                        class: "float-right"
                      })
                    ]))
                  : (_openBlock(), _createBlock(_component_Icon, {
                      key: 1,
                      type: "plus",
                      class: "cursor-pointer float-right",
                      onClick: onChooseUser
                    }))
              ]),
              _: 1
            }),
            _createVNode(_component_FormLine, { class: "mb-2" }, {
              default: _withCtx(() => [
                _createVNode(_component_Label, null, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('admin.ask_disclosure_message_title')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_TextInput, {
                  placeholder: _ctx.$t('admin.ask_disclosure_message_placeholder'),
                  modelValue: ask.value.message,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((ask.value.message) = $event)),
                  class: "w-5/6"
                }, null, 8, ["placeholder", "modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_FormLine, null, {
              default: _withCtx(() => [
                _createVNode(_component_Label, null, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('admin.secured_yivi_attributes')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_FormObjectInput, {
                  template: securedRoomTemplate.value,
                  modelValue: ask.value.attributes,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((ask.value.attributes) = $event))
                }, null, 8, ["template", "modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_FormLine, null, {
              default: _withCtx(() => [
                _createVNode(_component_Label, null, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('admin.ask_disclosure_where_room_title')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_TextInput, {
                  placeholder: _ctx.$t('admin.ask_disclosure_where_room_placeholder'),
                  modelValue: ask.value.where_room,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((ask.value.where_room) = $event)),
                  class: "flex"
                }, null, 8, ["placeholder", "modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_FormLine, null, {
              default: _withCtx(() => [
                _createVNode(_component_Label, null, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('admin.ask_disclosure_where_title')), 1)
                  ]),
                  _: 1
                }),
                (ask.value.where_room)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.$t('admin.ask_disclosure_where_public')), 1))
                  : (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.$t('admin.ask_disclosure_where_private')), 1))
              ]),
              _: 1
            })
          ], 32)
        ]),
        _: 1
      }, 8, ["title", "buttons"]))
    : _createCommentVNode("", true)
}
}

})