import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, withKeys as _withKeys, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex justify-between" }
const _hoisted_2 = ["title"]

import { ref, onMounted, computed } from 'vue';
	import { User as MatrixUser } from 'matrix-js-sdk';
	import { useRouter } from 'vue-router';
	import { usePubHubs } from '@/core/pubhubsStore';
	import { useUser } from '@/store/store';
	import { FilteredListEvent } from '@/types/components';

	
export default /*@__PURE__*/_defineComponent({
  __name: 'DiscoverUsers',
  emits: ['close'],
  setup(__props, { emit: __emit }) {

	const router = useRouter();
	const pubhubs = usePubHubs();
	const user = useUser();
	const emit = __emit;
	const showList = ref(false);

	const users = ref([] as Array<MatrixUser>);

	onMounted(async () => {
		users.value = await pubhubs.getUsers();
	});

	function focus(focus: boolean) {
		showList.value = focus;
	}

	const usersList = computed(() => {
		let list = users.value as any;
		list = list.map((user: any) => {
			return {
				userId: user.userId,
				displayName: user.displayName,
				avatarUrl: user.avatarUrl,
			};
		});
		// Remove self from list
		list = list.filter((u: any) => u.userId !== user.user.userId && u.rawDisplayName !== 'notices');
		return list;
	});

	async function addNewPrivateRoom(other: any) {
		const room = await pubhubs.createPrivateRoomWith(other);
		if (room) {
			const room_id = room.room_id;
			close();
			await router.push({ name: 'room', params: { id: room_id } });
		} else {
			close();
		}
	}

	async function close() {
		focus(false);
		emit('close');
	}

	async function filter(event: FilteredListEvent) {
		if (event.length < 10) {
			let foundUsers = await pubhubs.findUsers(event.filter);
			foundUsers = foundUsers.map((user) => {
				user.userId = user.user_id;
				user.displayName = user.display_name;
				user.avatarUrl = user.avatar_url;
				return user;
			});
			// combine and unique
			users.value = [...users.value, ...foundUsers];
			users.value = users.value.filter((user, index, arr) => {
				return arr.findIndex((item) => item.userId === user.userId) === index;
			});
		}
	}

return (_ctx: any,_cache: any) => {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_FilteredList = _resolveComponent("FilteredList")!

  return (_openBlock(), _createElementBlock("div", {
    class: "pl-6 pr-8 relative",
    onFocusin: _cache[2] || (_cache[2] = ($event: any) => (focus(true))),
    onClick: _cache[3] || (_cache[3] = ($event: any) => (focus(true))),
    onKeydown: _cache[4] || (_cache[4] = _withKeys(($event: any) => (focus(false)), ["esc"])),
    onMouseleave: _cache[5] || (_cache[5] = ($event: any) => (focus(false)))
  }, [
    _createVNode(_component_Icon, {
      type: "compass",
      class: "absolute -ml-2 bg-white dark:bg-hub-background-2"
    }),
    _createVNode(_component_FilteredList, {
      items: usersList.value,
      filterKey: "displayName",
      sortby: "displayName",
      placeholder: _ctx.$t('rooms.private_search_user'),
      onClick: _cache[0] || (_cache[0] = ($event: any) => (addNewPrivateRoom($event))),
      onFilter: _cache[1] || (_cache[1] = ($event: any) => (filter($event))),
      inputClass: 'pl-6',
      listClass: '-mt-[17px] border rounded-md shadow-md',
      showCompleteList: showList.value
    }, {
      item: _withCtx(({ item }) => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("span", {
            title: item.userId,
            class: "grow truncate w-100"
          }, _toDisplayString(item.displayName), 9, _hoisted_2),
          _createVNode(_component_Icon, {
            type: "plus",
            class: "flex-none"
          })
        ])
      ]),
      _: 1
    }, 8, ["items", "placeholder", "showCompleteList"])
  ], 32))
}
}

})