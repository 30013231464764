import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex justify-items-stretch" }
const _hoisted_2 = { class: "flex-grow" }

import { ref, computed } from 'vue';

	
	import { icons } from '@/assets/icons';

export default /*@__PURE__*/_defineComponent({
  __name: 'Toggler',
  props: {
		icon: {
			type: String,
			default: 'edit',
			validator(value: string) {
				return Object.keys(icons).includes(value);
			},
		},
		color: {
			type: String,
			default: 'text-black dark:text-white',
		},
		activeColor: {
			type: String,
			default: 'text-red',
		},
	},
  setup(__props) {

	const props = __props;

	const iconClass = computed(() => {
		if (toggleState.value) {
			return props.activeColor;
		}
		return props.color;
	});

	const toggleState = ref(false);

	function toggle() {
		if (toggleState.value) {
			toggleState.value = false;
		} else {
			toggleState.value = true;
		}
	}

return (_ctx: any,_cache: any) => {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _renderSlot(_ctx.$slots, "title")
      ]),
      _createElementVNode("div", null, [
        _createVNode(_component_Icon, {
          type: __props.icon,
          class: _normalizeClass([iconClass.value, "cursor-pointer"]),
          onClick: _cache[0] || (_cache[0] = ($event: any) => (toggle()))
        }, null, 8, ["type", "class"])
      ])
    ]),
    _createElementVNode("div", null, [
      _renderSlot(_ctx.$slots, "content", { state: toggleState.value })
    ])
  ]))
}
}

})