import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["innerHTML"]

import { M_MessageEvent } from '@/types/events';
	
export default /*@__PURE__*/_defineComponent({
  __name: 'Message',
  props: {
    event: {}
  },
  setup(__props: any) {

	

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("p", {
    innerHTML: _ctx.event.content.ph_body,
    class: "text-ellipsis overflow-hidden"
  }, null, 8, _hoisted_1))
}
}

})