import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { ref, computed, inject, onMounted } from 'vue';

	
export default /*@__PURE__*/_defineComponent({
  __name: 'TabPill',
  setup(__props) {

	const registerTabHeader = inject('registerTabHeader') as Function;
	const setActiveTab = inject('setActiveTab') as Function;
	const isActiveTab = inject('isActiveTab') as Function;

	const tab = ref(0);

	onMounted(() => {
		tab.value = registerTabHeader();
	});

	const activeClass = computed(() => {
		let c = '';
		if (tab.value > 1) {
			c += 'ml-2';
		}
		if (isActiveTab(tab.value)) {
			c += ' bg-white dark:bg-transparent';
		} else {
			c += ' bg-transparent opacity-50';
		}
		return c;
	});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["tabs-tab inline-block rounded-t border border-b-0 px-2 py-1 cursor-pointer z-20 theme-light:border-gray theme-light:text-gray", activeClass.value]),
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(setActiveTab)(tab.value)))
  }, [
    _renderSlot(_ctx.$slots, "default", {
      active: _unref(isActiveTab)(tab.value)
    })
  ], 2))
}
}

})