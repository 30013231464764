import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col items-center md:items-start md:flex-row mb-4" }
const _hoisted_2 = { class: "md:w-2/6 font-semibold text-gray" }
const _hoisted_3 = { class: "md:w-4/6 flex flex-col md:flex-row justify-between" }
const _hoisted_4 = { class: "flex justify-center md:justify-normal md:flex-col md:space-y-4 mt-5 md:mr-3" }
const _hoisted_5 = { for: "avatar" }
const _hoisted_6 = { class: "flex flex-col md:flex-row mb-4" }
const _hoisted_7 = { class: "w-2/6 font-semibold text-gray" }
const _hoisted_8 = { class: "flex flex-col md:flex-row mb-4" }
const _hoisted_9 = { class: "w-2/6 font-semibold text-gray" }
const _hoisted_10 = {
  title: "Hub specific User ID",
  class: "md:w-4/6 p-1 text-gray-light italic text-lg"
}
const _hoisted_11 = {
  key: 0,
  class: "rounded-lg bg-green-dark text-white p-2 mt-2"
}

import { onMounted } from 'vue';
	import { useUser, useSettings, useDialog, buttonsSubmitCancel, DialogSubmit, DialogButtonAction } from '@/store/store';
	import { useI18n } from 'vue-i18n';
	import { FormDataType, useFormState } from '@/composables/useFormState';
	import { fileUpload } from '@/composables/fileUpload';
	import { usePubHubs } from '@/core/pubhubsStore';
	import { useUserColor } from '@/composables/useUserColor';
	import { useMatrixFiles } from '@/composables/useMatrixFiles';

	
export default /*@__PURE__*/_defineComponent({
  __name: 'SettingsDialog',
  setup(__props) {

	const { t } = useI18n();
	const user = useUser();
	const settings = useSettings();
	const dialog = useDialog();
	const { data, setSubmitButton, setData, updateData, dataIsChanged, message, setMessage, validationErrors } = useFormState();
	const pubhubs = usePubHubs();
	const { imageTypes, uploadUrl } = useMatrixFiles();
	const { color, bgColor } = useUserColor();

	setData({
		displayName: {
			value: '',
			validation: { required: true, min_length: 2, max_length: settings.getDisplayNameMaxLength },
			show_validation: { required: false, max_length: true },
		},
		avatarUrl: {
			value: '',
			// To keep the original mxc.
			tmp: '',
		},
	});

	onMounted(() => {
		setSubmitButton(dialog.properties.buttons[0]);
	});

	onMounted(() => {
		data.displayName.value = user.user.displayName as FormDataType;
		data.avatarUrl.value = user.avatarUrl as FormDataType;
		if (data.avatarUrl.value !== undefined) {
			setData({
				avatarUrl: {
					value: data.avatarUrl.value as string,
					tmp: '',
				},
			});
		}
	});

	function dialogAction(action: DialogButtonAction) {
		if (action === DialogSubmit) {
			submit();
		}
	}

	async function submit() {
		if (dataIsChanged('displayName')) {
			const newDisplayName = data.displayName.value as string;
			await pubhubs.changeDisplayName(newDisplayName);
			setMessage(t('settings.displayname_changed', [newDisplayName]));
			updateData('displayName', '');
		}
		if (dataIsChanged('avatarUrl')) {
			const newAvatarUrl = data.avatarUrl.value as string;
			user.avatarUrl = newAvatarUrl;
			await pubhubs.changeAvatar(data.avatarUrl.tmp);
		}
	}

	// Avatar related functions
	async function uploadAvatar(event: Event) {
		const accessToken = pubhubs.Auth.getAccessToken();
		if (accessToken) {
			const errorMsg = t('errors.file_upload');
			await fileUpload(errorMsg, accessToken, uploadUrl, imageTypes, event, (uri) => {
				// Update the user store for avatar url to overcome synapse slow updates in user profile.
				data.avatarUrl.tmp = uri;
				data.avatarUrl.value = uri;
				// Update the form data i.e., there is a change and submit button is enabled.
				updateData('avatarUrl', uri);
			});
		} else {
			console.error('Access Token is invalid for File upload.');
		}
	}

	async function removeAvatar() {
		data.avatarUrl.value = '';
		updateData('avatarUrl', '');
	}

return (_ctx: any,_cache: any) => {
  const _component_Avatar = _resolveComponent("Avatar")!
  const _component_Icon = _resolveComponent("Icon")!
  const _component_TextInput = _resolveComponent("TextInput")!
  const _component_ValidationErrors = _resolveComponent("ValidationErrors")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    title: _ctx.$t('settings.title'),
    buttons: _unref(buttonsSubmitCancel),
    onClose: _cache[4] || (_cache[4] = ($event: any) => (dialogAction($event)))
  }, {
    default: _withCtx(() => [
      _createElementVNode("form", {
        onSubmit: _cache[3] || (_cache[3] = _withModifiers(() => {}, ["prevent"]))
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("label", _hoisted_2, _toDisplayString(_ctx.$t('settings.avatar')), 1),
          _createElementVNode("input", {
            type: "file",
            id: "avatar",
            accept: "image/png, image/jpeg, image/svg",
            class: "hidden",
            ref: "file",
            onChange: _cache[0] || (_cache[0] = ($event: any) => (uploadAvatar($event)))
          }, null, 544),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_Avatar, {
              class: _normalizeClass([_unref(bgColor)(_unref(color)(_unref(user).user.userId)), "w-32 h-32 rounded-full"]),
              userId: _unref(user).user.userId,
              img: _unref(data).avatarUrl.value
            }, null, 8, ["class", "userId", "img"]),
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("label", _hoisted_5, [
                _createVNode(_component_Icon, {
                  size: "lg",
                  type: "edit",
                  class: "group-hover:block cursor-pointer"
                })
              ]),
              _createVNode(_component_Icon, {
                size: "lg",
                type: "bin",
                class: "group-hover:block cursor-pointer",
                onClick: removeAvatar
              })
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("label", _hoisted_7, _toDisplayString(_ctx.$t('settings.displayname')), 1),
          _createVNode(_component_TextInput, {
            class: "md:w-4/6 p-1 border rounded focus:outline-none focus:border-blue-500",
            name: "displayname",
            modelValue: _unref(data).displayName.value,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(data).displayName.value) = $event)),
            modelModifiers: { trim: true },
            placeholder: _ctx.$t('settings.displayname'),
            onChanged: _cache[2] || (_cache[2] = ($event: any) => (_unref(updateData)('displayName', $event)))
          }, null, 8, ["modelValue", "placeholder"])
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("label", _hoisted_9, _toDisplayString(_ctx.$t('settings.userId')), 1),
          _createElementVNode("div", _hoisted_10, _toDisplayString(_unref(user).user.userId), 1)
        ])
      ], 32),
      _createVNode(_component_ValidationErrors, { errors: _unref(validationErrors) }, null, 8, ["errors"]),
      (_unref(message) !== '')
        ? (_openBlock(), _createElementBlock("div", _hoisted_11, _toDisplayString(_unref(message)), 1))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["title", "buttons"]))
}
}

})