import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "menu-item pl-5 pr-8 hover:dark:bg-gray-middle hover:bg-lightgray py-2 transition-all duration-150 ease-in-out" }

import { Room } from '@/store/rooms';
	import { useRooms } from '@/store/store';

	
export default /*@__PURE__*/_defineComponent({
  __name: 'MenuItem',
  props: {
		to: {
			type: [String, Object],
			default: '',
		},
		icon: {
			type: String,
			default: 'circle',
		},
		// active: {
		// 	type: Boolean,
		// 	default: false,
		// },
		roomInfo: {
			type: [Room, Object],
			default: Object,
		},
	},
  setup(__props) {

	const rooms = useRooms();

	const props = __props;

	function isSecuredRoom() {
		if (props.roomInfo?.roomId !== undefined) {
			return rooms.roomIsSecure(props.roomInfo.roomId);
		}
		return false;
	}

return (_ctx: any,_cache: any) => {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_TruncatedText = _resolveComponent("TruncatedText")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("li", _hoisted_1, [
    _createVNode(_component_router_link, {
      to: __props.to,
      class: "flex gap-2"
    }, {
      default: _withCtx(() => [
        (isSecuredRoom())
          ? (_openBlock(), _createBlock(_component_Icon, {
              key: 0,
              type: "lock",
              class: "shrink-0 text-blue dark:text-green"
            }))
          : (_openBlock(), _createBlock(_component_Icon, {
              key: 1,
              class: "shrink-0 text-blue dark:text-green",
              type: __props.icon
            }, null, 8, ["type"])),
        _createVNode(_component_TruncatedText, { class: "w-full" }, {
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, "default")
          ]),
          _: 3
        })
      ]),
      _: 3
    }, 8, ["to"])
  ]))
}
}

})