import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["alt", "src"]

import { useMatrixFiles } from '@/composables/useMatrixFiles';
	import { M_ImageMessageEventContent } from '@/types/events';
	
export default /*@__PURE__*/_defineComponent({
  __name: 'MessageImage',
  props: {
    message: {}
  },
  setup(__props: any) {

	const { formUrlfromMxc } = useMatrixFiles();

	const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("img", {
    alt: _ctx.message.body,
    src: _unref(formUrlfromMxc)(_ctx.message.url),
    class: "max-w-full h-auto"
  }, null, 8, _hoisted_1))
}
}

})