import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "text-black text-xl" }
const _hoisted_2 = {
  key: 0,
  class: "flex items-center justify-center"
}
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "text-black flex justify-center mt-4" }
const _hoisted_5 = { class: "text-lg text-gray" }

import { useMatrixFiles } from '@/composables/useMatrixFiles';
	import { usePubHubs } from '@/core/pubhubsStore';
	import { useRooms } from '@/store/store';
	import filters from '@/core/filters';
	import { buttonsOkCancel } from '@/store/dialog';

	
export default /*@__PURE__*/_defineComponent({
  __name: 'FileUploadDialog',
  props: {
    file: {},
    mxcPath: {}
  },
  emits: ['close'],
  setup(__props: any, { emit: __emit }) {

	const rooms = useRooms();
	const pubhubs = usePubHubs();

	const { imageTypes, formUrlfromMxc } = useMatrixFiles();
	const emit = __emit;

	const props = __props;

	async function close(action: number = 0) {
		if (action === 1) {
			submit();
		} else {
			emit('close');
		}
	}

	async function submit() {
		if (imageTypes.includes(props.file?.type)) {
			pubhubs.addImage(rooms.currentRoomId, props.mxcPath);
		} else {
			pubhubs.addFile(rooms.currentRoomId, props.file, props.mxcPath);
		}
		close();
	}

return (_ctx: any,_cache: any) => {
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    buttons: _unref(buttonsOkCancel),
    onClose: _cache[0] || (_cache[0] = ($event: any) => (close($event)))
  }, {
    header: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.$t('file.upload_file')), 1)
    ]),
    default: _withCtx(() => [
      (_unref(imageTypes).includes(props.file?.type))
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("img", {
              src: _unref(formUrlfromMxc)(_ctx.mxcPath),
              class: "max-w-full max-h-96 rounded-lg"
            }, null, 8, _hoisted_3)
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.file.name) + " (" + _toDisplayString(`${_unref(filters).formatBytes(_ctx.file.size)}`) + ")", 1)
      ])
    ]),
    _: 1
  }, 8, ["buttons"]))
}
}

})