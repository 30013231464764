import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { computed } from 'vue';
	import { useI18n } from 'vue-i18n';
	import Room from '@/model/rooms/Room';
	
export default /*@__PURE__*/_defineComponent({
  __name: 'RoomName',
  props: {
		room: {
			type: Room,
		},
	},
  setup(__props) {

	const { t } = useI18n();

	const props = __props;

	// The general room name is of the form #General:<server_name>.
	const name = computed(() => {
		if (props.room?.name.startsWith('#General:')) {
			return t('rooms.name_general_room');
		} else if (props.room?.name.startsWith('#Feedback:')) {
			return t('rooms.name_feedback_room');
		} else {
			return props.room?.name;
		}
	});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("span", null, _toDisplayString(name.value), 1))
}
}

})