const en = {
	'plugin-example': {
		menu: 'Plugin Example',
		page: {
			title: 'Plugin Example',
			subtitle: 'Example',
			text: 'Lorem ipsum dolor sit amet.',
		},
	},
};

export { en };
