const nl = {
	'plugin-example': {
		menu: 'Plugin Voorbeeld',
		page: {
			title: 'Voorbeeld',
			subtitle: 'Voorbeeld Plugin',
			text: 'NL: Lorem ipsum dolor sit amet.',
		},
	},
};

export { nl };
