import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "bg-hub-background-3 flex px-2 gap-3 items-center rounded-md cursor-pointer" }
const _hoisted_2 = {
  key: 0,
  class: "text-nowrap"
}
const _hoisted_3 = ["title"]

import { useUserColor } from '@/composables/useUserColor';
	import { M_MessageEvent } from '@/types/events';
	import { computed } from 'vue';
	import Room from '@/model/rooms/Room';

	type Props = {
		event: M_MessageEvent;
		// Whether or not to show the text "In reply to:" inside the snippet.
		showInReplyTo?: boolean;
		room: Room;
	};

	
export default /*@__PURE__*/_defineComponent({
  __name: 'MessageSnippet',
  props: {
    event: {},
    showInReplyTo: { type: Boolean, default: false },
    room: {}
  },
  setup(__props: any) {

	const { color, textColor } = useUserColor();

	const props = __props;

	const userColor = computed(() => color(props.event.sender) || 0);
	const text = computed(() => {
		return props.event.content?.body as string;
	});

return (_ctx: any,_cache: any) => {
  const _component_UserDisplayName = _resolveComponent("UserDisplayName")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.showInReplyTo)
      ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_ctx.$t('message.in_reply_to')), 1))
      : _createCommentVNode("", true),
    _createElementVNode("p", {
      class: _normalizeClass(_unref(textColor)(userColor.value))
    }, [
      _createVNode(_component_UserDisplayName, {
        user: _ctx.event.sender,
        room: _ctx.room
      }, null, 8, ["user", "room"])
    ], 2),
    _createElementVNode("p", {
      class: "truncate",
      title: text.value
    }, _toDisplayString(text.value), 9, _hoisted_3)
  ]))
}
}

})