import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "text-center"
}

import { computed } from 'vue';
	import { useRooms } from '@/store/store';

	
export default /*@__PURE__*/_defineComponent({
  __name: 'UnreadMarker',
  props: {
		currentEventId: {
			type: String,
			required: true,
		},
		currentUserId: {
			type: String,
			required: true,
		},
	},
  setup(__props) {

	const rooms = useRooms();

	const props = __props;

	const unreadMarker = computed(() => {
		const readEventId = rooms.currentRoom?.getEventReadUpTo(props.currentUserId, true);
		if (rooms.unreadMessageNotification() > 0 && readEventId === props.currentEventId) return true;
		return false;
	});

return (_ctx: any,_cache: any) => {
  return (unreadMarker.value)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[0] || (_cache[0] = [
        _createElementVNode("div", { class: "inline-block bg-blue-light text-white text-sm font-medium text-center px-3.5 py-1.5 rounded-full m-auto" }, "Unread Messages", -1),
        _createElementVNode("div", { class: "bg-blue-light h-[1px] -mt-4" }, null, -1)
      ])))
    : _createCommentVNode("", true)
}
}

})