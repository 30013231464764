<template>
	<HeaderFooter class="pl-3">
		<template #header>
			<H1 class="m-0 text-blue font-bold">{{ $t('plugin-example.page.title') }}</H1>
		</template>

		<H2>{{ $t('plugin-example.page.subtitle') }}</H2>
		<p>{{ $t('plugin-example.page.text') }}</p>
	</HeaderFooter>
</template>
