import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex bg-black text-white overflow-hidden" }

import { ref } from 'vue';

	
export default /*@__PURE__*/_defineComponent({
  __name: 'Json',
  props: {
		json: Object,
	},
  setup(__props) {

	

	const folded = ref(true);

	function toggleFold() {
		folded.value = !folded.value;
	}

return (_ctx: any,_cache: any) => {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Icon, {
      type: folded.value ? 'chevron-right' : 'chevron-down',
      onClick: _cache[0] || (_cache[0] = ($event: any) => (toggleFold())),
      class: "text-green"
    }, null, 8, ["type"]),
    _createElementVNode("pre", {
      class: _normalizeClass(["font-mono text-sm p-1", folded.value ? 'h-16' : ''])
    }, _toDisplayString(JSON.stringify(__props.json, null, 2)), 3)
  ]))
}
}

})