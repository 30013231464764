import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"


export default /*@__PURE__*/_defineComponent({
  __name: 'UploadPicker',
  emits: ['click'],
  setup(__props, { emit: __emit }) {

	const emit = __emit;

	function click() {
		emit('click');
	}

return (_ctx: any,_cache: any) => {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("button", {
    class: "flex flex-col items-center m-2",
    onClick: click
  }, [
    _createVNode(_component_Icon, {
      class: "dark:text-white",
      type: "upload"
    }),
    _createElementVNode("p", null, _toDisplayString(_ctx.$t('message.upload_file')), 1)
  ]))
}
}

})