import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["onClick"]
const _hoisted_2 = { class: "flex items-center gap-4 py-2" }

import { ref, computed, watch, onMounted } from 'vue';
	import { useRooms } from '@/store/store';
	import { TRoomMember } from '@/model/rooms/TRoomMember';
	import Room from '@/model/rooms/Room';

	type Props = {
		msg?: string;
		left: number;
		top: number;
		room: Room;
	};

	
export default /*@__PURE__*/_defineComponent({
  __name: 'Mention',
  props: {
    msg: { default: undefined },
    left: { default: 0 },
    top: { default: 0 },
    room: { default: undefined }
  },
  emits: ['click'],
  setup(__props: any, { emit: __emit }) {

	const emit = __emit;

	const visible = ref(false); // Control the visibility of the user list div

	// Therefore, message Direction keeps track of the simulating backspace.
	const messageDirection = ref(0);
	const rooms = useRooms();
	let users = ref([] as TRoomMember[]);
	const checkEmptyList = ref(false);
	const elContainer = ref<HTMLElement | null>(null);

	const props = __props;

	onMounted(() => {
		users.value = rooms.currentRoom?.getOtherJoinedMembers() || [];
	});

	// Watch for changes in the props.msg to control visibility
	watch(props, () => {
		users.value = rooms.currentRoom?.getOtherJoinedMembers() || [];
		displayBoxVisibility();
	});

	const filteredUsers = computed(() => {
		const query = props.msg;
		if (query.endsWith('@')) {
			return displayAllUsers();
		} else {
			return filterUsers(query);
		}
	});

	function displayBoxVisibility() {
		// Simulating backspace or delete key with messageDirection.
		// When to

		if (props.msg.length < messageDirection.value && messageDirection.value !== 0) {
			visible.value = false;
			messageDirection.value = 0;
		} else if (props.msg.endsWith('@')) {
			messageDirection.value = props.msg.length;
			visible.value = true;
			//Disable the unnecessary empty box if there are no users in search box.
		} else if (checkEmptyList.value) {
			visible.value = false;
		}
	}

	function displayAllUsers() {
		return users.value;
	}

	function filterUsers(query: string) {
		const searchTerm = query.slice(query.lastIndexOf('@') + 1);
		const newUserList = users.value.filter((user) => user.rawDisplayName !== undefined && user.rawDisplayName.toLowerCase().includes(searchTerm.toLowerCase()));
		checkEmptyList.value = newUserList.length < 1 ? true : false;
		return newUserList;
	}

	function clickedItem(item: any) {
		emit('click', item);
		messageDirection.value = 0;
		visible.value = false; // Close the div when a user is clicked
	}

	function getStyle() {
		if (!elContainer.value) return;
		return {
			left: `${props.left}px`,
			top: `${props.top - 40 - elContainer.value.clientHeight}px`,
		};
	}

return (_ctx: any,_cache: any) => {
  const _component_Avatar = _resolveComponent("Avatar")!

  return (visible.value)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        ref_key: "elContainer",
        ref: elContainer,
        style: _normalizeStyle(getStyle()),
        class: "fixed w-fit max-h-52 shadow-lg rounded-lg overflow-y-auto scrollbar bg-lightgray-light dark:bg-gray"
      }, [
        _createElementVNode("ul", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(filteredUsers.value, (member, index) => {
            return (_openBlock(), _createElementBlock("li", {
              key: index,
              class: "group cursor-pointer hover:bg-lightgray-dark hover:dark:bg-gray-middle px-4",
              onClick: ($event: any) => (clickedItem(member))
            }, [
              _createElementVNode("div", _hoisted_2, [
                _createVNode(_component_Avatar, {
                  userId: member.userId,
                  room: _ctx.room
                }, null, 8, ["userId", "room"]),
                _createElementVNode("div", null, _toDisplayString(member.rawDisplayName), 1)
              ])
            ], 8, _hoisted_1))
          }), 128))
        ])
      ], 4))
    : _createCommentVNode("", true)
}
}

})