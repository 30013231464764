import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "dark:text-white text-xs font-medium px-2.5 py-1.5 -m-2.5"
}

import { watchEffect, ref } from 'vue';
	import { useRooms } from '@/store/rooms';
	import { useUser } from '@/store/user';

	
export default /*@__PURE__*/_defineComponent({
  __name: 'ReadReceipt',
  props: {
		timestamp: {
			type: Number,
			required: true,
		},
		sender: {
			type: String,
			required: true,
		},
	},
  setup(__props) {

	const currentUser = useUser();
	const rooms = useRooms();
	const props = __props;

	let numOfUsersRead = ref(0);

	// tracks reactive property accessed  with side effects
	watchEffect(() => {
		readByOtherUsers();
	});

	function readByOtherUsers() {
		if (!rooms.currentRoom) return;
		if (props.sender === currentUser.user.userId) {
			const readTimeStampOfUsers = getReadTimeStampForRoomUsers();

			if (!readTimeStampOfUsers) return;
			// Users whose timestamp is greater than currentTimeStamp of the user, it means they have read the message.
			numOfUsersRead.value = readTimeStampOfUsers.filter((number) => number > props.timestamp).length;
			return numOfUsersRead.value;
		}
		numOfUsersRead.value = 0;
		return numOfUsersRead.value;
	}

	// //Get read receipt timestamp of all other users.
	function getReadTimeStampForRoomUsers(): number[] {
		if (!rooms.currentRoom) return []; // Return an empty array if there's no current room

		const room = rooms.currentRoom;
		const currentUserID = currentUser.user.userId;

		// We need to get private room members list each time because new members can be added.
		const roomUsers = room.getOtherJoinedMembers();

		const readTimestamps: number[] = [];

		roomUsers.forEach((user) => {
			if (user.user && user.user.userId !== currentUserID) {
				const readReceipt = room.getReadReceiptForUserId(user.user.userId);
				if (readReceipt) {
					readTimestamps.push(readReceipt.data.ts);
				}
			}
		});

		return readTimestamps;
	}

return (_ctx: any,_cache: any) => {
  const _component_Icon = _resolveComponent("Icon")!

  return (_unref(numOfUsersRead) > 0)
    ? (_openBlock(), _createElementBlock("span", _hoisted_1, [
        _createVNode(_component_Icon, {
          type: "filled_tick",
          size: "sm",
          class: "inline mb-1"
        }),
        _createTextVNode(" " + _toDisplayString(_unref(numOfUsersRead) > 0 ? _ctx.$t('others.read_receipt') + ' ' + _unref(numOfUsersRead) : ' ') + " ", 1),
        (_unref(numOfUsersRead) === 1)
          ? (_openBlock(), _createBlock(_component_Icon, {
              key: 0,
              type: "single_user",
              size: "sm",
              class: "inline mb-1"
            }))
          : _createCommentVNode("", true),
        (_unref(numOfUsersRead) > 1)
          ? (_openBlock(), _createBlock(_component_Icon, {
              key: 1,
              type: "two_users",
              size: "sm",
              class: "inline mb-1"
            }))
          : _createCommentVNode("", true),
        _createTextVNode(" " + _toDisplayString(_unref(numOfUsersRead) > 2 ? '+' : ''), 1)
      ]))
    : _createCommentVNode("", true)
}
}

})