import { defineComponent as _defineComponent } from 'vue'
import { resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { computed } from 'vue';

	type Props = {
		direction?: 'Vertical' | 'Horizontal';
		// Given as a tailwind size (For example, h-thickness).
		thickness?: string;
	};

	
export default /*@__PURE__*/_defineComponent({
  __name: 'Line',
  props: {
    direction: { default: 'Horizontal' },
    thickness: { default: '1' }
  },
  setup(__props: any) {

	const props = __props;

	const size = computed(() => {
		switch (props.direction) {
			case 'Horizontal':
				return `h-${props.thickness}`;

			case 'Vertical':
			default:
				return `w-${props.thickness}`;
		}
	});

return (_ctx: any,_cache: any) => {
  const _directive_tw_class = _resolveDirective("tw-class")!

  return _withDirectives((_openBlock(), _createElementBlock("hr", null, null, 512)), [
    [_directive_tw_class, size.value + ' mt-2 mb-4 text-gray theme-light:text-gray dark:text-white theme-dark:text-white']
  ])
}
}

})