import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, isRef as _isRef, vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, withKeys as _withKeys } from "vue"

const _hoisted_1 = ["placeholder", "disabled"]
const _hoisted_2 = {
  key: 0,
  class: "w-full border border-black px-2 py-1 rounded-lg absolute z-50 bg-white shadow-md"
}
const _hoisted_3 = ["onClick"]

import { onMounted, computed, PropType } from 'vue';
	import { InputType, Options, useFormInputEvents, usedEvents } from '@/composables/useFormInputEvents';
	import { useKeyStrokes } from '@/composables/useKeyStrokes';

	type Props = {
		options: PropType<Options>;
		value: string | Object;
		label?: string;
		disabled: Boolean;
	};

	
export default /*@__PURE__*/_defineComponent({
  __name: 'AutoComplete',
  props: {
    options: {},
    value: { default: undefined },
    label: { default: 'label' },
    disabled: { default: undefined }
  },
  emits: usedEvents,
  setup(__props: any, { emit: __emit }) {

	const props = __props;

	const emit = __emit;
	const { value: search, setValue, update } = useFormInputEvents(emit);
	const { setItems, cursor, cursorDown, cursorUp, reset, selectItem, selectItemByEnter } = useKeyStrokes();

	onMounted(() => {
		setValue(props.value as InputType);
	});

	const result = computed(() => {
		if (search.value === '' || search.value === undefined) {
			setItems([]);
			return [];
		}

		let matches = 0;

		const result = props.options.filter((item) => {
			//@ts-ignore
			if (item[props.label].toLowerCase().includes(search.value.toLowerCase()) && matches < 10) {
				matches++;
				return item;
			}
		});
		setItems(result);
		return result;
	});

	const enter = () => {
		const item = selectItemByEnter();
		select(item);
	};

	const select = (item: any) => {
		selectItem(item);
		setValue(item[props.label]);
		update(item[props.label]);
	};

	const click = (item: any) => {
		setValue(item[props.label]);
		update(item[props.label]);
	};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    onKeydown: [
      _cache[1] || (_cache[1] = _withKeys(_withModifiers(($event: any) => (_unref(cursorDown)()), ["prevent"]), ["arrow-down"])),
      _cache[2] || (_cache[2] = _withKeys(_withModifiers(($event: any) => (_unref(cursorUp)()), ["prevent"]), ["arrow-up"])),
      _cache[3] || (_cache[3] = _withKeys(_withModifiers(($event: any) => (enter()), ["stop"]), ["enter"])),
      _cache[4] || (_cache[4] = _withKeys(_withModifiers(($event: any) => {
			_unref(reset)();
			search.value = undefined;
		}, ["stop"]), ["esc"]))
    ],
    class: "relative w-full"
  }, [
    _withDirectives(_createElementVNode("input", {
      type: "text",
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(search) ? (search).value = $event : null)),
      class: "w-full border px-2 py-1 rounded-lg dark:bg-transparent theme-light:border-black theme-light:text-black dark:text-white dark:border-white focus:border-black focus:outline-0 focus:outline-offset-0 focus:ring-0",
      placeholder: _ctx.$t('others.typing'),
      disabled: _ctx.disabled === true
    }, null, 8, _hoisted_1), [
      [_vModelText, _unref(search)]
    ]),
    (result.value.length > 1)
      ? (_openBlock(), _createElementBlock("ul", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(result.value, (item, index) => {
            return (_openBlock(), _createElementBlock("li", {
              key: index,
              onClick: ($event: any) => (click(item)),
              class: _normalizeClass(["cursor-pointer text-black", { 'bg-lightgray': _unref(cursor) === index }])
            }, _toDisplayString(item[_ctx.label]), 11, _hoisted_3))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ], 32))
}
}

})