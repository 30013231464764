import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { key: 0 }

import { RoomMember } from 'matrix-js-sdk';
	
export default /*@__PURE__*/_defineComponent({
  __name: 'PrivateRoomMembersList',
  props: {
		members: {
			type: Array<RoomMember>,
			required: true,
		},
	},
  setup(__props) {

	

return (_ctx: any,_cache: any) => {
  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.members, (member, index) => {
    return (_openBlock(), _createElementBlock("span", { key: index }, [
      (index > 0)
        ? (_openBlock(), _createElementBlock("span", _hoisted_1, " • "))
        : _createCommentVNode("", true),
      _createTextVNode(" " + _toDisplayString(member.name), 1)
    ]))
  }), 128))
}
}

})