import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, withKeys as _withKeys, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["placeholder", "title", "value", "disabled"]

import { useFormInputEvents, usedEvents } from '@/composables/useFormInputEvents';

	
export default /*@__PURE__*/_defineComponent({
  __name: 'TextInput',
  props: {
		placeholder: {
			type: String,
			default: '',
		},
		modelValue: {
			type: String,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
	},
  emits: usedEvents,
  setup(__props, { emit: __emit }) {

	const props = __props;

	const emit = __emit;
	const { update, changed, submit, cancel } = useFormInputEvents(emit, props.modelValue);

return (_ctx: any,_cache: any) => {
  const _directive_tw_class = _resolveDirective("tw-class")!

  return _withDirectives((_openBlock(), _createElementBlock("input", {
    class: "truncate rounded-lg border py-1 px-2 theme-light:text-black dark:bg-transparent dark:text-white dark:border-white theme-light:border-black focus:border-black focus:outline-0 focus:outline-offset-0 focus:ring-0",
    type: "text",
    placeholder: __props.placeholder,
    title: __props.placeholder,
    value: __props.modelValue,
    disabled: props.disabled,
    onInput: _cache[0] || (_cache[0] = ($event: any) => (_unref(update)($event.target.value))),
    onKeyup: _cache[1] || (_cache[1] = ($event: any) => (_unref(changed)())),
    onKeydown: [
      _cache[2] || (_cache[2] = _withKeys(($event: any) => (_unref(submit)()), ["enter"])),
      _cache[3] || (_cache[3] = _withKeys(($event: any) => (_unref(cancel)()), ["esc"]))
    ]
  }, null, 40, _hoisted_1)), [
    [_directive_tw_class, 'w-full']
  ])
}
}

})