import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["title"]

import { useSlots, computed, VNodeNormalizedChildren, isVNode } from 'vue';

	
export default /*@__PURE__*/_defineComponent({
  __name: 'TruncatedText',
  setup(__props) {

	const slots = useSlots();
	const slotText = computed(() => {
		if (typeof slots.default !== 'undefined') {
			let text = '';
			let children: VNodeNormalizedChildren = slots.default()[0].children;
			// Traverse children tree so this could be used inside a slot of another component
			if (Array.isArray(children)) {
				children.forEach((child) => {
					if (isVNode(child) && child !== null) {
						text = child.children as string;
					}
				});
			}
			return text;
		}
		return '';
	});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("span", {
    class: "truncate w-full inline-block",
    title: slotText.value
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 8, _hoisted_1))
}
}

})