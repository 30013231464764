<template>
	<HeaderFooter class="pl-3">
		<template #header>
			<H1 class="m-0 text-blue font-bold">Tool</H1>
		</template>

		<H2>Example Plugin</H2>
		<p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat.</p>
	</HeaderFooter>
</template>
