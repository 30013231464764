import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "absolute bottom-[100px] left-60",
  id: "yivi-web-form-2"
}

import { onMounted } from 'vue';
	import { useI18n } from 'vue-i18n';
	import { usePubHubs } from '@/core/pubhubsStore';
	import { useRooms } from '@/store/store';
	import { DisclosureAttribute, AskDisclosureMessage, YiviSigningSessionResult } from '@/lib/signedMessages';

	
export default /*@__PURE__*/_defineComponent({
  __name: 'DisclosureYivi',
  props: {
    ask: {}
  },
  emits: ['close'],
  setup(__props: any, { emit: __emit }) {

	const { t } = useI18n();
	const pubhubs = usePubHubs();
	const rooms = useRooms();
	const emit = __emit;

	const props = __props;

	onMounted(() => {
		console.debug(`DisclosureYivi:onMounted(${props.ask.userId}, ${props.ask.message}, ${props.ask.attributes.map((a: any) => a.yivi)})`);
		const attributeNames = props.ask.attributes.map((a: DisclosureAttribute) => a.yivi);

		// start the signing (disclosure)
		const messageToSign = t('admin.disclosure_sign_message');
		startDisclosure(messageToSign, attributeNames);
	});

	function startDisclosure(message: string, attributes: string[]) {
		console.debug(`startDisclosure: m=${message}, a=[${attributes}]`);
		rooms.yiviAskDisclosure(message, attributes, rooms.currentRoomId, pubhubs.Auth.getAccessToken(), finishDisclosure);
	}

	function finishDisclosure(result: YiviSigningSessionResult) {
		console.debug(`finishDisclosure: type=${result.type}, status=${result.status}, proofStatus=${result.proofStatus}`);
		console.debug(`finishDisclosure: disclosed=${JSON.stringify(result.disclosed, null, 2)}`);
		if (result.status === 'DONE' && result.proofStatus === 'VALID') {
			for (const i of result.disclosed) {
				for (const j of i) {
					console.debug(`  disclosed: ${j.status} ${j.id} ${j.rawvalue}`);
				}
			}
		}
		// post the disclosed attributes as a signed message
		pubhubs.addSignedMessage(props.ask.replyToRoomId, result);
		// TODO: Present the response to the moderator, and let them decide what to do with it.

		// clean up: close windows, reset state
		emit('close');
	}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1))
}
}

})