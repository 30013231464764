import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex" }
const _hoisted_2 = { class: "text-sm leading-4" }

import { useRooms } from '@/store/store';
	
export default /*@__PURE__*/_defineComponent({
  __name: 'PluginRoomExample',
  setup(__props) {

	const rooms = useRooms();

return (_ctx: any,_cache: any) => {
  const _component_H1 = _resolveComponent("H1")!
  const _component_Json = _resolveComponent("Json")!
  const _component_HeaderFooter = _resolveComponent("HeaderFooter")!

  return (_unref(rooms).currentRoomExists)
    ? (_openBlock(), _createBlock(_component_HeaderFooter, {
        key: 0,
        class: "pl-3"
      }, {
        header: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", null, [
              _createVNode(_component_H1, { class: "m-0 text-blue font-bold" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_unref(rooms).currentRoom?.name), 1)
                ]),
                _: 1
              }),
              _createElementVNode("p", _hoisted_2, "Example of room plugin by id. [ roomId: " + _toDisplayString(_unref(rooms).currentRoom?.roomId) + " ]", 1)
            ])
          ])
        ]),
        default: _withCtx(() => [
          _createVNode(_component_Json, {
            json: _unref(rooms).currentRoom?.timelineGetEvents()
          }, null, 8, ["json"])
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}
}

})