import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, isRef as _isRef, vModelSelect as _vModelSelect, withKeys as _withKeys, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = ["disabled"]
const _hoisted_2 = ["value", "selected"]

import { PropType } from 'vue';
	import { Options, useFormInputEvents, usedEvents } from '@/composables/useFormInputEvents';

	
export default /*@__PURE__*/_defineComponent({
  __name: 'Select',
  props: {
		options: {
			type: Array as PropType<Options>,
			required: true,
		},
		value: {
			type: String,
			default: '',
		},
		disabled: {
			type: Boolean,
			default: false,
		},
	},
  emits: usedEvents,
  setup(__props, { emit: __emit }) {

	const props = __props;

	const emit = __emit;
	const { value: inputValue, setValue, setOptions, selectOption, optionIsSelected, changed, submit, cancel } = useFormInputEvents(emit);

	setValue(props.value);
	setOptions(props.options);

return (_ctx: any,_cache: any) => {
  const _directive_tw_class = _resolveDirective("tw-class")!

  return _withDirectives((_openBlock(), _createElementBlock("select", {
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(inputValue) ? (inputValue).value = $event : null)),
    class: "w-full border px-2 py-1 rounded-lg dark:bg-transparent theme-light:border-black theme-light:text-black dark:text-white dark:border-white focus:border-black focus:outline-0 focus:outline-offset-0 focus:ring-0",
    disabled: props.disabled,
    onChange: _cache[1] || (_cache[1] = ($event: any) => (_unref(changed)())),
    onInput: _cache[2] || (_cache[2] = ($event: any) => (_unref(selectOption)($event.target))),
    onKeydown: [
      _cache[3] || (_cache[3] = _withKeys(($event: any) => (_unref(submit)()), ["enter"])),
      _cache[4] || (_cache[4] = _withKeys(($event: any) => (_unref(cancel)()), ["esc"]))
    ]
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.options, (option) => {
      return (_openBlock(), _createElementBlock("option", {
        key: option.value,
        value: option.value,
        selected: _unref(optionIsSelected)(option.value)
      }, _toDisplayString(option.label), 9, _hoisted_2))
    }), 128))
  ], 40, _hoisted_1)), [
    [_vModelSelect, _unref(inputValue)],
    [_directive_tw_class, 'w-full']
  ])
}
}

})