import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { ref, provide } from 'vue';

	
export default /*@__PURE__*/_defineComponent({
  __name: 'Tabs',
  setup(__props) {

	const activeTab = ref(1);
	const numberOfTabs = ref(0);
	const numberOfTabHeaders = ref(0);

	const registerTabHeader = () => {
		numberOfTabHeaders.value++;
		return numberOfTabHeaders.value;
	};

	const registerTab = () => {
		numberOfTabs.value++;
		return numberOfTabs.value;
	};

	const removeTab = () => {
		numberOfTabHeaders.value--;
		numberOfTabs.value--;
		if (activeTab.value > numberOfTabs.value) {
			activeTab.value = numberOfTabs.value;
		}
	};

	const setActiveTab = (tab: number) => {
		activeTab.value = tab;
	};

	const isActiveTab = (tab: number): boolean => {
		return activeTab.value === tab;
	};

	provide('registerTabHeader', registerTabHeader);
	provide('registerTab', registerTab);
	provide('removeTab', removeTab);
	provide('setActiveTab', setActiveTab);
	provide('isActiveTab', isActiveTab);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _renderSlot(_ctx.$slots, "default")
  ]))
}
}

})