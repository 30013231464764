import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, withKeys as _withKeys, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["value", "checked", "disabled"]

import { useFormInputEvents, usedEvents } from '@/composables/useFormInputEvents';

	
export default /*@__PURE__*/_defineComponent({
  __name: 'Checkbox',
  props: {
		placeholder: {
			type: String,
			default: '',
		},
		modelValue: {
			type: Boolean,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
	},
  emits: usedEvents,
  setup(__props, { emit: __emit }) {

	const props = __props;

	const emit = __emit;
	const { update, cancel } = useFormInputEvents(emit, props.modelValue);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("input", {
    type: "checkbox",
    class: "h-6 w-6 mt-1 rounded-md text-green bg-transparent border-1 border-black dark:border-white theme-light:border-gray focus:outline-0 focus:outline-offset-0 focus:ring-0 focus:ring-offset-0 focus:ring-offset-width-0 focus:shadow-0",
    value: __props.modelValue,
    checked: __props.modelValue,
    disabled: props.disabled,
    onInput: _cache[0] || (_cache[0] = ($event: any) => (_unref(update)($event.target.checked))),
    onKeydown: _cache[1] || (_cache[1] = _withKeys(($event: any) => (_unref(cancel)()), ["esc"]))
  }, null, 40, _hoisted_1))
}
}

})