import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderSlot as _renderSlot, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "pt-2" }

import { ref, inject, onMounted } from 'vue';

	
export default /*@__PURE__*/_defineComponent({
  __name: 'TabContent',
  setup(__props) {

	const registerTab = inject('registerTab') as Function;
	const isActiveTab = inject('isActiveTab') as Function;

	const tab = ref(0);

	onMounted(() => {
		tab.value = registerTab();
	});

return (_ctx: any,_cache: any) => {
  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _renderSlot(_ctx.$slots, "default")
  ], 512)), [
    [_vShow, _unref(isActiveTab)(tab.value)]
  ])
}
}

})